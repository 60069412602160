import React from "react";
import ReactDOM from "react-dom/client";
import { PrimeReactProvider } from "primereact/api";

import "primereact/resources/themes/lara-light-teal/theme.css";
import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
import "primeflex/primeflex.css"

import './index.css';
import App from './App';

const primeReactOptions = {
    locale: 'pt-br',
    ripple: true, // optional animation for the supported components such as buttons
    cssTransition: true,
    // inputStyle: "filled",
    zIndex: {
        modal: 1100, // dialog, sidebar
        overlay: 1000, // dropdown, overlaypanel
        menu: 1000, // overlay menus
        tooltip: 1100, // tooltip
        toast: 1200, // toast
    },
    autoZIndex: true,
};


ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <PrimeReactProvider value={primeReactOptions}>
            <App />
        </PrimeReactProvider>
    </React.StrictMode>,
);
