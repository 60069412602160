import React from 'react';
import { Menubar } from 'primereact/menubar';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge';
import { Avatar } from 'primereact/avatar';
import { useTranslation } from 'react-i18next';
import { locale as primeLocale } from 'primereact/api';
import '../../i18n';
import {SplitButton} from "primereact/splitbutton";

export default function SideBar() {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        primeLocale(lng);
    };

    const itemRenderer = (item) => (
        <a className="flex align-items-center p-menuitem-link">
            <span className={item.icon} />
            <span className="mx-2">{item.label}</span>
            {item.badge && <Badge className="ml-auto" value={item.badge} />}
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
    );

    const items = [
        {
            label: t('navigation.home'),
            icon: 'pi pi-home'
        },
        {
            label: t('navigation.features'),
            icon: 'pi pi-star'
        },
        {
            label: t('navigation.projects'),
            icon: 'pi pi-search',
            items: [
                {
                    label: t('navigation.core'),
                    icon: 'pi pi-bolt',
                    shortcut: '⌘+S',
                    template: itemRenderer
                },
                {
                    label: t('navigation.blocks'),
                    icon: 'pi pi-server',
                    shortcut: '⌘+B',
                    template: itemRenderer
                },
                {
                    label: t('navigation.uiKit'),
                    icon: 'pi pi-pencil',
                    shortcut: '⌘+U',
                    template: itemRenderer
                },
                {
                    separator: true
                },
                {
                    label: t('navigation.templates'),
                    icon: 'pi pi-palette',
                    items: [
                        {
                            label: t('navigation.apollo'),
                            icon: 'pi pi-palette',
                            badge: 2,
                            template: itemRenderer
                        },
                        {
                            label: t('navigation.ultima'),
                            icon: 'pi pi-palette',
                            badge: 3,
                            template: itemRenderer
                        }
                    ]
                }
            ]
        },
        {
            label: t('navigation.contact'),
            icon: 'pi pi-envelope',
            badge: 3,
            template: itemRenderer
        }
    ];

    const languages = [
        {
            label: 'Português',
            icon: 'pi pi-refresh',
            visible: i18n.language != 'pt',
            command: () => {
                changeLanguage('pt')
            }
        },
        {
            label: 'Inglês',
            icon: 'pi pi-times',
            visible: i18n.language != 'en',
            command: () => {
                changeLanguage('en')
            }
        },
        {
            label: 'Espanhol',
            icon: 'pi pi-external-link',
            visible: i18n.language != 'es',
            command: () => {
                changeLanguage('es')
            }
        }
    ];

    const start = <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" height="40" className="mr-2"></img>;
    const end = (
        <div className="flex align-items-center gap-2">
            <InputText placeholder={t('navigation.search')} type="text" className="w-8rem sm:w-auto" />
            <SplitButton label={t(`languages.${i18n?.language}`)} model={languages} text />
            <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png" shape="circle" />
        </div>
    );

    return (
        <div className="card">
            <Menubar model={items} start={start} end={end} />
        </div>
    );
}
