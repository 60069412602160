import axios from "axios";
import {API_URL} from "./constants";
import {isEmpty} from "./index";

const instance = axios.create({
    baseURL: API_URL,
    timeout: 5000,
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
    headers: {
        get: { 'Content-Type': 'application/json', },
        delete: { 'Content-Type': 'application/json', },
        post: { 'Content-Type': 'multipart/form-data', },
        put: { 'Content-Type': 'multipart/form-data', },
    },
});

let abortController = {};

const defaultResponse = {
    "status": 500,
    "message": 'Parece que há um problema aqui, estamos verificando...',
}

function handleCallError (e) {
    return (e.code === "ERR_CANCELED")
        ? { "status": 0, "message": e.message, }
        : { "status": e.response?.status, "message": e.response.data?.message, };
}

function handleAbortController(endpoint, ignoreAbort) {
    if (abortController[endpoint] && !ignoreAbort) abortController[endpoint].abort();
}

export const httpGet = async (endpoint = '', params = {}, ignoreAbort = false) => {
    console.log(`[get] endpoint ${endpoint}`);
    console.log({ '[get] call params': { params } });
    let ret = defaultResponse;

    handleAbortController(endpoint, ignoreAbort);
    try {
        abortController[endpoint] = new AbortController();
        const signal = abortController[endpoint].signal;

        await instance.get( endpoint, { signal, params, } )
            .then((response) => {
                console.log({ '[get] response': { endpoint, response } });
                ret = response?.data;
                ret.status = response?.data?.status ?? response?.status;
            }).catch(e => {
                handleCallError(e);
            })
    } catch (e) { console.error({ '[get] outer catch': e.message }); }

    return ret;
}

export const httpPost = async (endpoint, body, isFormData = false, ignoreAbort = false) => {
    console.log(`[post] endpoint ${endpoint}`);
    console.log({ '[post] call params': { body, isFormData } });
    let ret = defaultResponse;

    handleAbortController(endpoint, ignoreAbort);
    try {
        abortController[endpoint] = new AbortController();
        const signal = abortController[endpoint].signal;
        const contentType= isFormData ? 'application/x-www-form-urlencoded': 'application/json';
        await instance.post(endpoint, (isFormData ? body : { ...body }), {
            signal: signal,
            headers: { 'X-CSRFToken': body?.csrfmiddlewaretoken , "Content-Type": contentType },
        }).then((response) => {
            console.log({ '[post] response': { endpoint, response } })
            ret = (isEmpty(response?.data)) ? response : response.data;
            ret.status = response?.data?.status ?? response?.status;
        }).catch(e => {
            handleCallError(e);
        });
    } catch (e) { console.error({ '[post] outer catch': e }); }

    return ret;
}

export const httpPut = async (endpoint, body, ignoreAbort = false) => {
    console.log(`[put] endpoint ${endpoint}`);
    console.log({ '[put] call params': { body } });
    let ret = defaultResponse;

    handleAbortController(endpoint, ignoreAbort);
    try {
        abortController[endpoint] = new AbortController();
        const signal = abortController[endpoint].signal;

        await instance.put(endpoint, body, {
            signal: signal,
            headers: { 'X-CSRFToken': body?.csrfmiddlewaretoken },
        }).then((response) => {
            console.log({ '[put] response': { endpoint, response } })
            ret = (isEmpty(response?.data)) ? response : response.data;
            ret.status = response?.data?.status ?? response?.status;
        }).catch(e => {
            handleCallError(e);
        });
    } catch (e) { console.error({ '[put] outer catch': e }); }

    return ret;
}

export const httpDelete = async (endpoint, ignoreAbort = false) => {
    console.log(`[delete] endpoint ${endpoint}`);
    let ret = defaultResponse;

    handleAbortController(endpoint, ignoreAbort);
    try {
        abortController[endpoint] = new AbortController();
        const signal = abortController[endpoint].signal;

        await instance.delete(endpoint, {
            signal: signal,
        }).then((response) => {
            console.log({ '[delete] response': { endpoint, response } })
            ret = (isEmpty(response?.data)) ? response : response.data;
            ret.status = response?.data?.status ?? response?.status;
        }).catch(e => {
            handleCallError(e);
        });
    } catch (e) { console.error({ '[delete] outer catch': e }); }

    return ret;
}
