import {DEFAULT_APP_TITLE} from "./constants.js";


export function getAppTitle() {
  if(import.meta.env.VITE_MODE) return `(${import.meta.env.VITE_MODE}) ${DEFAULT_APP_TITLE}`;
  return DEFAULT_APP_TITLE;
}
export function setAppTitle(title) {
  if (isEmpty(title)) return;
  document.title = `${title} :: ${getAppTitle()}`;
}

export const isArray = (obj) => (obj === null) ? false : ((typeof obj).toLowerCase() === "object");
export const isZero = (str) => {
    try {
        str *= 1;
        if (str === 0) { return true; }
    } catch (e) { }
    return false;
};
export const isEmpty = (str, zeroIsEmpty = true) => {
    if (str === null || str === false || str === undefined ||
        (isArray(str) && str.length === 0)
    ) {
        return true;
    } else if (isArray(str) && str.length > 0) { return false; }

    try {
        str = str.toString().trim().toLowerCase();
        if (
            str === "" || str === "false" ||
            str === "null" || str === "undefined" ||
            str === "nan"
        ) { return true; }
    } catch (e) { }

    if (zeroIsEmpty) return isZero(str);
    return false;
};