import {httpGet, httpPost} from "../helpers/axios";

const AuthService = {
    getToken: async function() {
        return await httpGet('/csrf_token')
    },

    // authenticate: async function(body) {
    //     return await httpPost('/authenticate', body, true);
    // },
    //
    login: async function(body) {
        return await httpPost('/login', body, true);
    },
    //
    // logout: async function() {
    //     return await httpPost('/logout');
    // },
}

export default AuthService;
