
import React, {useState, useEffect, useRef } from 'react';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {COOKIES_NAMES} from "../../helpers/constants";
import Cookies from "universal-cookie";
import {isEmpty, setAppTitle} from "../../helpers";
import AuthService from "../../services/AuthService";
import { Toast } from 'primereact/toast';

export default function Auth() {
    const { token, setToken } = useAuth();
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [username, setUsername]= useState('');
    const [password, setPassword]= useState('');
    const toast = useRef(null);

    async function handleLogin() {
        let hasTokenError = false;
        await AuthService.getToken().then(response => {
            console.log({ '[handleLogin] getToken': response });
            if (isEmpty(response?.success)) {
                console.error('[handleLogin getToken] Falha csrf_token')
                hasTokenError = true;
            }
        }).catch(e => {
            console.error({ '[handleLogin getToken] Erro csrf_token': e })
            hasTokenError = true;
        });

        if(hasTokenError){
            toast.current.show({severity:'error', summary: 'Error', detail: 'Houve um erro na requisição!', life: 3000});
            return;
        }

        await AuthService.login({
            username: username,
            password: password,
            csrfmiddlewaretoken: cookies.get(COOKIES_NAMES.DJANGO_CSRF_KEY),
        }).then(response => {
            let txt= 'Credenciais incorretas';
            if (response?.status == 200) {
                txt= 'Login realizado com sucesso!';
                cookies.set(COOKIES_NAMES.LOGGED_IN, Date.now());
                cookies.set(COOKIES_NAMES.USER_INFO, JSON.stringify(response?.user));
                toast.current.show({severity:'success', summary: 'Success', detail: txt, life: 3000});
                setTimeout(()=>{
                    navigate('/');
                },400)
            }else{
                console.log('toast.current',toast.current);
                toast.current.show({severity:'error', summary: 'Error', detail: txt, life: 3000});
            }
        }).catch(e => {
            console.error({ '[handleLogin authenticate] Erro': e })
            toast.current.show({severity:'error', summary: 'Error', detail: 'Houve um erro na requisição!', life: 3000});
        });
    }

    useEffect(() => {
        setAppTitle('Login');
        if (
          isEmpty(cookies.get(COOKIES_NAMES.LOGGED_IN)) ||
          isEmpty(cookies.get(COOKIES_NAMES.USER_INFO)) ||
          isEmpty(cookies.get(COOKIES_NAMES.SESSION_DJANGO)) ||
          isEmpty(cookies.get(COOKIES_NAMES.DJANGO_CSRF_KEY))
        ) {
          cookies.remove(COOKIES_NAMES.LOGGED_IN);
          cookies.remove(COOKIES_NAMES.USER_INFO);
          cookies.remove(COOKIES_NAMES.SESSION_DJANGO);
          cookies.remove(COOKIES_NAMES.DJANGO_CSRF_KEY);
        }
      }, [])

    return (
        <>
            <Toast ref={toast} />
            <div className="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
                <div className="card">
                    <div className="flex flex-column md:flex-row">
                        <div className="w-full md:w-5 flex flex-column align-items-center gap-3 py-5">
                            <div className="flex flex-wrap align-items-center">
                                <label className="w-6rem">Username</label>
                                <InputText id="username" type="text" className="w-12rem" value={username} onChange={(e) => setUsername(e.target.value)}/>
                            </div>
                            <div className="flex flex-wrap align-items-center gap-2">
                                <label className="w-6rem">Password</label>
                                <InputText id="password" type="password" className="w-12rem" value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <Button type="button" label="Login" icon="pi pi-user" className="w-10rem mx-auto" onClick={handleLogin}></Button>
                        </div>
                        <div className="w-full md:w-2">
                            <Divider layout="vertical" className="hidden md:flex">
                                <b>OR</b>
                            </Divider>
                            <Divider layout="horizontal" className="flex md:hidden" align="center">
                                <b>OR</b>
                            </Divider>
                        </div>
                        <div className="w-full md:w-5 flex align-items-center justify-content-center py-5">
                            <Button type="button" label="Sign Up" icon="pi pi-user-plus" severity="success" className="w-10rem" ></Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
