import { BrowserRouter } from "react-router-dom";
import RouteManager from "./components/RouteManager";
import '../i18n.js';
import { locale as primeLocale } from 'primereact/api';
function App() {
    primeLocale('en');
    return (
        <BrowserRouter>
            <RouteManager />
        </BrowserRouter>
    );
}

export default App;
