import {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Cookies from "universal-cookie";
import {isEmpty} from "../helpers";
import {COOKIES_NAMES} from "../helpers/constants";
import SideBar from "../components/SideBar.jsx";
// import SidebarDash from "../components/SidebarDash.jsx";

export default function Layout() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { token, setToken } = useAuth();
    const cookies = new Cookies();
    const userData  = cookies.get(COOKIES_NAMES.USER_INFO); //? JSON.parse(cookies.get(COOKIES_NAMES.USER_INFO)) : null;

    useEffect(() => {
        if(['/login'].includes(location.pathname)){ //TODO: includes ?
            if(
                !isEmpty(cookies.get(COOKIES_NAMES.LOGGED_IN)) &&
                !isEmpty(cookies.get(COOKIES_NAMES.USER_INFO)) &&
                !isEmpty(cookies.get(COOKIES_NAMES.SESSION_DJANGO))
            ){
                navigate('/');
                return;
            }
        }else if(
            isEmpty(cookies.get(COOKIES_NAMES.LOGGED_IN)) ||
            isEmpty(cookies.get(COOKIES_NAMES.USER_INFO)) ||
            isEmpty(cookies.get(COOKIES_NAMES.SESSION_DJANGO))
        ){
            console.log('cookies.get(COOKIES_NAMES.LOGGED_IN)',cookies.get(COOKIES_NAMES.LOGGED_IN));
            console.log('cookies.get(COOKIES_NAMES.USER_INFO)',cookies.get(COOKIES_NAMES.USER_INFO));
            console.log('cookies.get(COOKIES_NAMES.SESSION_DJANGO)',cookies.get(COOKIES_NAMES.SESSION_DJANGO));
            setToken(null);
            cookies.remove(COOKIES_NAMES.LOGGED_IN);
            cookies.remove(COOKIES_NAMES.USER_INFO);
            navigate('/login');
            return;
        }
        setToken(cookies.get(COOKIES_NAMES.LOGGED_IN));
    }, [])

    return (
        ['/login'].includes(location.pathname)
        ? <Outlet />
        : <>
            <SideBar/>
            <div>
                <h1>Titulo do layout</h1>
                <div>
                    Usuário logado
                    <p>{userData?.username}</p>
                    <p>{userData?.email}</p>
                </div>
                <Outlet />
            </div>
          </>
    );
}
