import { useEffect } from "react";

import { Routes, Route, useLocation, Navigate, useNavigate } from "react-router-dom";

import Layout from "../layouts/Layout";
import Auth from "../pages/Auth";
import Dashboard from "../pages/Dashboard";


export default function RouteManager() {
    const location = useLocation();
    const navigate = useNavigate();

    // function handleProtectedRoutes() {
    //     if (PROTECTED_ROUTES.includes(location.pathname)) {
    //         if (ROLE_RANK[userInfo?.role] > getRoutesRoleRank(location.pathname)) {
    //             logger.l(`[handleProtectedRoutes] pathname ${location.pathname}`);
    //             navigate('/dashboards');
    //             window.location.reload();
    //         }
    //     }
    // }

    // useEffect(() => {
    //     handleProtectedRoutes();
    // }, [location]);
    console.log('[RouteManager] location', location);

    return (
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Layout />}>
                <Route index element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/login" element={<Auth />} />
            </Route>
        </Routes>
    );
}