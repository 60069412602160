
import React from 'react';
import useAuth from "../../hooks/useAuth";

export default function Dashboard() {
    const { token, setToken } = useAuth();

    return (
        <div className="card">
            <h1>
                Dashboard
            </h1>
            <p>Acesso com login</p>
            <p>Token: {token}</p>
        </div>
    )
}
