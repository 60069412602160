
export const DEFAULT_APP_TITLE = 'Tickets';
// export const API_URL = 'http://127.0.0.1:8000/api/';
export const API_URL = 'https://react.vrltech.com.br/api/';

export const COOKIES_NAMES = {
    SESSION_DJANGO: 'sessionid',
    DJANGO_CSRF_KEY: 'csrftoken',
    LOGGED_IN: 'qrqpoyntrloeeyxmgulviarzxdabjja',
    USER_INFO: 'kdwxcskobrsuehpofbagywabcfobkoe'
}