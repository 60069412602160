import { useState } from 'react';
import Cookies from 'universal-cookie';
import {isEmpty} from "../helpers";
import {COOKIES_NAMES} from "../helpers/constants";

export default function useAuth() {
    const cookies = new Cookies();
    const [token, setToken] = useState(() => {
        return isEmpty(cookies.get(COOKIES_NAMES.LOGGED_IN)) ? null : cookies.get(COOKIES_NAMES.LOGGED_IN);
    });
    const removeToken = () => { setToken(null); }

    return {
        token,
        setToken,
        removeToken
    }
}
